// Import images
import indexImage from './images/backgrounds/ariel.jpg'

import groveLogo from './images/theGroveLogo.svg'
import groveBg from './images/the-grove-bg.jpg'
import groveImage from './images/backgrounds/the-grove.jpg'

import oaksLogo from './images/oaksLogo.svg'
import oaksBg from './images/the-oaks-bg.jpg'
import oaksImage from './images/backgrounds/chesterwell-oaks.jpg'

import parkLogo from './images/chesterwell-park-logo-1.svg'
import parkBg from './images/chesterwell-park-bg.png'
import parkImage from './images/backgrounds/chesterwell-park.jpg'

interface Development {
    name: string,
    logoPath: string,
    bgPath: string,
    imagePath: string,
    url: string,
}

const config: {
    indexImagePath: string,
    developments: Array<Development>
} = {
    indexImagePath: indexImage,
    developments: [
        {
            name: 'The Grove',
            logoPath: groveLogo,
            bgPath: groveBg,
            imagePath: groveImage,
            url: 'https://chesterwell-the-grove.merseahomes.think-online.co.uk/site'
        },
        {
            name: 'The Oaks',
            logoPath: oaksLogo,
            bgPath: oaksBg,
            imagePath: oaksImage,
            url: 'https://chesterwell-oaks.merseahomes.think-online.co.uk/site'
        },
        {
            name: 'Chesterwell Park',
            logoPath: parkLogo,
            bgPath: parkBg,
            imagePath: parkImage,
            url: 'https://chesterwell-park.merseahomes.think-online.co.uk/site'
        },
    ]
}

export type {Development}
export {config};